export const PRECISION_URLS = {
  asApplied() {
    return `${this.main}/as-applied` as const;
  },
  asAppliedDetail() {
    return `${this.main}/as-applied/:taskDataId/:taskId`;
  },
  main: "precision-farming",
  services() {
    return `${this.main}/services` as const;
  },
  seasons() {
    return `${this.main}/seasons` as const;
  },
  seasonDetail() {
    return `${this.seasons()}/:seasonId` as const;
  },
  servicesV2() {
    return `${this.main}/v2/services` as const;
  },
  seasonsDashboard() {
    return `${this.seasons()}/dashboard` as const;
  },
  servicesDetail() {
    return `${this.services()}/:parcelId` as const;
  },
  servicesDetailV2() {
    return `${this.servicesV2()}/:parcelId/seasons/:seasonId` as const;
  },
} as const;

export const FILE_MAX_SIZE_MB = 10;
