import React from "react";

import { Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";
import { generatePath, Link, useLocation } from "react-router-dom";

import { PRECISION_URLS } from "../../../../../core/precision/precision.constants";

import analyticsIcon from "../../../../../assets/img/icons/satellite/precision/dashboard/analytics.svg";
import analyticsDisabledIcon from "../../../../../assets/img/icons/satellite/precision/dashboard/analytics_disabled.svg";
import requestQuoteDisabledIcon from "../../../../../assets/img/icons/satellite/precision/dashboard/request-quote_disabled.svg";
import requestQuoteIcon from "../../../../../assets/img/icons/satellite/precision/dashboard/request_quote.svg";
import { PRECISION_TABS } from "../../../../../core/precision/containers/PrecisionDetailV2";
import {
  DashboardParcelTo,
  DashboardSeasonTo,
  PrecisionFarmingProduct,
} from "../../../../../generated/api/satellite";
import { useFarmIds } from "../../../../../shared/api/client.utils";
import { HistoryPrevState } from "../../../../types";

type Props = {
  products: PrecisionFarmingProduct[];
  data: DashboardParcelTo;
  season: DashboardSeasonTo;
};

const SeasonCellIconBar = ({ data, products, season }: Props) => {
  const classes = useStyles();
  const location = useLocation();
  const disabledColor = "#F6F6F6";
  const yieldIsActive = products.includes(
    PrecisionFarmingProduct.INTERNAL_YIELD_MAPS,
  );

  const monitoringIsActive =
    products.includes(PrecisionFarmingProduct.PLANET_BIOMONITORING_SAVI) ||
    products.includes(PrecisionFarmingProduct.GISAT_BIOPHYSICAL_MONITORING);

  const { farmIds } = useFarmIds();
  const getRootUrl = (path: string) =>
    generatePath(
      `/farm/${farmIds}/${PRECISION_URLS.servicesDetailV2()}/${path}`,
      {
        parcelId: data.parcelId,
        seasonId: season.seasonId,
      },
    );

  return (
    <div className={classes.icons}>
      <Tooltip
        title={<FormattedMessage id="PrecisionTableService.monitoring" />}
      >
        {monitoringIsActive ? (
          <Link
            className={classes.icon}
            style={{
              background: "#CCE7F5",
            }}
            to={{
              pathname: getRootUrl(PRECISION_TABS.monitoring.path),
              state: {
                previousLocation: { ...location },
              } as HistoryPrevState,
            }}
          >
            <img alt="analytics" src={analyticsIcon} />
          </Link>
        ) : (
          <div
            className={classes.icon}
            style={{
              background: disabledColor,
            }}
          >
            <img alt="analytics_disabled" src={analyticsDisabledIcon} />
          </div>
        )}
      </Tooltip>
      <Tooltip
        title={<FormattedMessage id="PrecisionTableService.yieldMaps" />}
      >
        {yieldIsActive ? (
          <Link
            className={classes.icon}
            style={{
              background: "#FFE1FC",
            }}
            to={{
              pathname: getRootUrl(PRECISION_TABS.yieldMaps.path),
              state: {
                previousLocation: { ...location },
              } as HistoryPrevState,
            }}
          >
            <img alt="request-quote" src={requestQuoteIcon} />
          </Link>
        ) : (
          <div
            className={classes.icon}
            style={{
              background: disabledColor,
            }}
          >
            <img alt="request-quote-disabled" src={requestQuoteDisabledIcon} />
          </div>
        )}
      </Tooltip>
    </div>
  );
};

export const useStyles = makeStyles(() => ({
  icons: {
    display: "flex",
    gap: 12,
  },
  icon: {
    padding: 4,
    height: 24,
    width: 24,
    borderRadius: "100%",
  },
}));

export { SeasonCellIconBar };
