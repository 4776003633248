import React from "react";

import { Grid } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { useQuery } from "@tanstack/react-query";
import { Link, useParams, useHistory } from "react-router-dom";

import { satelliteParcelQuery } from "../../../../core/precision/PrecisionV2.api";
import CfErrorPage from "../../../../shared/components/common/CfErrorPage/CfErrorPage";
import CfFormattedNumber from "../../../../shared/components/common/CfFormattedNumber/CfFormattedNumber";
import CfLoader from "../../../../shared/components/common/CfLoader/CfLoader";
import CfSwitcher from "../../../../shared/components/common/CfSwitcher/CfSwitcher";
import { useIsMobile } from "../../../../shared/hooks/useWidth";
import { ChevronLeftIcon } from "../../../../shared/icons/ChevronLeftIcon";
import { ChevronRightIcon } from "../../../../shared/icons/ChevronRightIcon";

import { YieldMapBasicInfo } from "./components/YieldMapBasicInfo";
import { YieldMapGeometries } from "./components/YieldMapGeometries";
import { YieldMapZonesMap } from "./components/YieldMapZonesMap";
import { getParcelDataQuery, getYieldSeasonQuery } from "./YieldMapsDetail.api";

interface Season {
  key: string;
  value: string;
}

const getUrl = (farmId: string, parcelId: string, seasonId: string) => {
  const url = `/farm/${farmId}/precision-farming/v2/services/${parcelId}/seasons/${seasonId}/yield-Maps`;

  return url;
};

const findSeasonIndex = (seasonId: string, seasons: Array<Season>): number => {
  const seasonIndex = seasons.findIndex((season) => season.key === seasonId);

  return seasonIndex;
};

const getPreviousSeason = (seasonId: string, seasons: Array<Season>) => {
  if (seasons.length < 1) {
    return null;
  }
  const seasonIndex = findSeasonIndex(seasonId, seasons);

  return seasonIndex < seasons.length - 1 ? seasons[seasonIndex + 1] : null;
};

const getNextSeason = (seasonId: string, seasons: Array<Season>) => {
  const seasonIndex = findSeasonIndex(seasonId, seasons);

  return seasonIndex > 0 ? seasons[seasonIndex - 1] : null;
};

const YieldMapsDetail = () => {
  const { farmId, parcelId, seasonId } = useParams<{
    farmId: string;
    parcelId: string;
    seasonId: string;
  }>();

  const parcel = useQuery(
    getParcelDataQuery(parcelId, {
      farmIds: farmId,
      "season-id": seasonId,
    }),
  );

  const seasons = useQuery(
    getYieldSeasonQuery(parcelId, {
      farmIds: farmId,
      product: "INTERNAL_YIELD_MAPS",
    }),
  );

  const parcelData = useQuery(
    satelliteParcelQuery(parcelId, {
      farmIds: farmId,
      "season-id": seasonId,
    }),
  );

  const classes = useStyles();
  const history = useHistory();
  const isMobile = useIsMobile();

  const fetchedData = parcel?.data?.data;
  const fetchedSeasons = seasons?.data?.data;

  if (parcel.isSuccess) {
    const tableData = [
      {
        name: "areaHa",
        value: (
          <>
            {fetchedData ? (
              <CfFormattedNumber value={fetchedData.areaHa} />
            ) : (
              <>-</>
            )}{" "}
            ha
          </>
        ),
      },
      { name: "cropName", value: fetchedData?.cropName },
      {
        name: "avgYieldTonnes",
        value: (
          <>
            {fetchedData ? (
              <CfFormattedNumber value={fetchedData.avgYieldTonnes} />
            ) : (
              <>-</>
            )}{" "}
            t/ha
          </>
        ),
      },
    ];

    const seasonsOptions =
      fetchedSeasons?.map((s) => ({
        key: s.season.id,
        value: s.season.name,
      })) ?? [];

    const selected = seasonsOptions.filter((season) => season.key === seasonId);
    const previousSeason = getPreviousSeason(seasonId, seasonsOptions);
    const nextSeason = getNextSeason(seasonId, seasonsOptions);

    const handleSeasonClick = (season: string) => {
      const newSeasonId = seasonsOptions.map((option) =>
        option.value === season ? option.key : null,
      );
      const newSeason = newSeasonId.filter((s) => s)[0] || "";
      const newUrl = getUrl(farmId, parcelId, newSeason);

      history.push(newUrl);
    };

    return (
      <CfErrorPage
        error={parcel.error || seasons.error || parcelData.error || {}}
      >
        <Grid className={classes.wrapper} container spacing={1.5}>
          <Grid item sm={5} xs={12}>
            <Grid container spacing={1.5}>
              <Grid item xs={12}>
                <div className={classes.seasonWrapper}>
                  {nextSeason ? (
                    <Link
                      aria-label="next season"
                      to={getUrl(farmId, parcelId, nextSeason.key)}
                    >
                      <ChevronLeftIcon />
                    </Link>
                  ) : (
                    <ChevronLeftIcon color="#d9d9d9" />
                  )}
                  <CfSwitcher
                    getItemId={(item) => item}
                    getItemValue={(item) => item}
                    items={seasonsOptions.map((season) => season.value)}
                    onMenuItemClick={handleSeasonClick}
                    selectedItemId={selected[0].value}
                    classes={{
                      buttonPanel: classes.cfSwitcherButtonPanel,
                      menuItem: classes.cfSwitcherMenuItem,
                      wrapper: classes.cfSwitcherWrapper,
                    }}
                  />

                  {previousSeason ? (
                    <Link
                      aria-label="previously season"
                      to={getUrl(farmId, parcelId, previousSeason.key)}
                    >
                      <ChevronRightIcon />
                    </Link>
                  ) : (
                    <ChevronRightIcon color="#d9d9d9" />
                  )}
                </div>
              </Grid>
              <Grid item xs={12}>
                {tableData ? <YieldMapBasicInfo data={tableData} /> : null}
              </Grid>
              <Grid item xs={12}>
                {fetchedData && parcelData.data ? (
                  <YieldMapZonesMap
                    data={fetchedData}
                    parcelData={parcelData.data.data}
                    parcelId={parcelId}
                  />
                ) : null}
              </Grid>
            </Grid>
          </Grid>
          <Grid
            className={isMobile ? classes.mobile : undefined}
            item
            sm={7}
            xs={12}
          >
            {fetchedData && parcelData.data ? (
              <YieldMapGeometries
                data={fetchedData}
                parcelData={parcelData.data.data}
                parcelId={parcelId}
              />
            ) : null}
          </Grid>
        </Grid>
      </CfErrorPage>
    );
  }
  return <CfLoader />;
};

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    borderColor: "#f7f7f7",
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      height: 500,
    },
  },
  seasonWrapper: {
    display: "flex",
    justifyContent: "space-between",
  },
  cfSwitcherButtonPanel: {
    "& button": {
      background: "#f7f7f7",
      borderRadius: "8px",
      color: "#000",
      textAlign: "right",
      fontFamily: "Roboto",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "16px",
      "&:hover": {
        background: "#eee",
      },
      "& svg": {
        fill: "#00A179",
      },
    },
  },
  cfSwitcherMenuItem: {
    color: "#848484",
    fontFamily: "Roboto",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "16px",
    "&:hover": {
      background: "#f3f3f3",
    },
  },
  cfSwitcherWrapper: {
    justifyContent: "center",
  },
  mobile: {
    margin: "40px 0 0",
  },
}));

export { YieldMapsDetail };
