import {
  queryOptions,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";

import {
  createSeason,
  getCrops,
  GetCropsParams,
  getDashboardPaged,
  GetDashboardPagedParams,
  getSeasons,
  GetSeasonsParams,
  SeasonsDashboardTo,
  updateSeasonParcelMetadata,
} from "../../../../generated/api/satellite";
import { useFarmIds } from "../../../../shared/api/client.utils";
import { staleTime } from "../../../../shared/query-client";
import { getCountedData } from "../helper";

import { apiKeys } from "./apiKeys";

const tableDataQuery = (
  payload: GetDashboardPagedParams,
  keepPreviousData: boolean,
) =>
  queryOptions({
    queryKey: apiKeys.parcels.list(payload),
    queryFn: async () => {
      const data = await getDashboardPaged(payload);
      return getCountedData(data);
    },
    staleTime: staleTime.Infinity,
    placeholderData: keepPreviousData ? (prev) => prev : undefined,
  });

const cropsQuery = (payload: GetCropsParams) =>
  queryOptions({
    queryKey: apiKeys.crops.list(payload),
    queryFn: () => getCrops(payload),
    staleTime: staleTime.Infinity,
  });

const useCreateSeasonMutation = () =>
  useMutation({
    mutationFn: ({
      params,
      payload,
    }: {
      payload: Parameters<typeof createSeason>[0];
      params: Parameters<typeof createSeason>[1];
    }) => createSeason(payload, params),
  });

const useUpdateSeasonParcelMutation = () => {
  const farmIds = useFarmIds();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (
      payload: Parameters<typeof updateSeasonParcelMetadata>[0] & {
        cropName: string;
      },
    ) => updateSeasonParcelMetadata(payload, farmIds),
    onMutate: (params) => {
      const snapshot = queryClient.getQueriesData({
        predicate: (query) =>
          query.queryKey.includes(apiKeys.parcels.listUniqueKey()),
      });

      if (!snapshot || !snapshot.length || !snapshot[0].length) {
        // eslint-disable-next-line no-console
        console.error("No queries found with the specified predicate");
        return;
      }

      const targetKey = snapshot[0][0];

      queryClient.setQueryData(
        targetKey,
        (prev: { data: SeasonsDashboardTo; count: number }) => {
          const resSeasons = prev.data.seasons.map((season) => {
            if (season.seasonId !== params.seasonId) {
              return season;
            }

            return {
              ...season,
              parcels: season.parcels.map((parcel) =>
                parcel?.parcelId === params.parcelId
                  ? {
                      ...parcel,
                      cropName: params.cropName,
                      cropInternalId: params.cropInternalId,
                      yieldInTonnes: params.yieldInTonnes,
                    }
                  : parcel,
              ),
            };
          });

          return {
            ...prev,
            data: {
              parcels: prev.data.parcels,
              seasons: resSeasons,
            },
          };
        },
      );

      return () => {
        queryClient.setQueryData(targetKey, snapshot);
      };
    },
    onError: (error, variables, rollback) => {
      rollback?.();
    },
    onSettled: () =>
      queryClient.invalidateQueries({
        predicate: (query) =>
          query.queryKey.includes(apiKeys.parcels.listUniqueKey()),
      }),
  });
};

const seasonsQuery = (payload: GetSeasonsParams) =>
  queryOptions({
    queryKey: apiKeys.seasons.detail(payload),
    queryFn: () => getSeasons(payload),
    staleTime: 24 * 60 * 60 * 1000, // 1 day
  });

export {
  tableDataQuery,
  cropsQuery,
  useCreateSeasonMutation,
  useUpdateSeasonParcelMutation,
  seasonsQuery,
};
