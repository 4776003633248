import React from "react";

import { connect } from "react-redux";

import { getPrecisionParcelListTextFilter } from "../selectors/precisionParcelList.selectors";

import { PRECISION_URLS } from "../precision.constants";

import { NAMESPACE as namespace } from "../reducer/precisionParcelList.reducer";

import CfTextFilter from "../../../common/components/CfTextFilter/CfTextFilter";
import { useCfTextFilter } from "../../../common/components/CfTextFilter/useCfTextFilter";

import PrecisionServicesTable from "./PrecisionServicesTable";
import { usePrecisionListStyles } from "./styles";

import { PrecisionState } from "../../../reducers/precision.reducer.types";

interface Props {
  farmId: string;
  textFilter: string;
}

export const PrecisionServicesV2 = ({ farmId, textFilter }: Props) => {
  const classes = usePrecisionListStyles();
  const { onChange } = useCfTextFilter(namespace);

  return (
    <div className={classes.wrapper}>
      <div>
        <div className={classes.toolbar}>
          <CfTextFilter
            initialValue={textFilter}
            name="precision-list-text-filter"
            onChange={onChange}
            translId="PrecisionListTextFilter.placeholder"
          />
        </div>
        <PrecisionServicesTable
          rootUrl={`/farm/${farmId}/${PRECISION_URLS.servicesV2()}`}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state: PrecisionState) => ({
  textFilter: getPrecisionParcelListTextFilter(state),
});

export default connect(mapStateToProps)(PrecisionServicesV2);
