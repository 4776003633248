import { toNumber } from "lodash";

import { RestrictionType } from "../../../../generated/api/agroevidence";
import { AnyTodo } from "../../../../types";

import { SubtractionResponse } from "../../../../shared/api/agroevidence/agroevidence.types";
import { InitialParcelToAdd } from "../../ActionOthers/actionOther.types";

export const mapRequestBodyParcelsSubtractionArea = (
  parcels: InitialParcelToAdd[],
) => {
  // Helper function to create a restriction if the value is non-zero or type is "Landscape" with zero value
  const createRestriction = (
    type: RestrictionType,
    value: number,
    key: "value" | "protectionLevel" = "value",
  ) => {
    if (!isNaN(value)) {
      if (value !== 0 || type === "Landscape") {
        return [{ type, [key]: value }];
      }
    }
    return [];
  };

  // Helper function to map parcel data into restriction objects
  const mapParcelToRestrictions = (parcel: InitialParcelToAdd) => ({
    parcelId: parcel.id,
    restrictions: [
      // Map absolute subtractable areas
      ...parcel.subtractableAreas.absolute
        .filter((sa: SubtractionResponse) => sa.isUsed === true)
        .map(({ type, value }) => ({ type, value })),

      // Add boundary restrictions if applicable
      ...createRestriction(
        RestrictionType.Boundary,
        toNumber(parcel.subtractableAreas.boundaryChecked),
      ),

      // Add water restrictions if applicable
      ...createRestriction(
        RestrictionType.Water,
        toNumber(parcel.subtractableAreas.waterChecked),
      ),

      // Add surface water protection zone restrictions
      ...createRestriction(
        RestrictionType.SurfaceWaterProtectionZones,
        toNumber(parcel.subtractableAreas.surfaceWaterProtectionZonesChecked),
        "protectionLevel",
      ),

      // Add ground water protection zone restrictions
      ...createRestriction(
        RestrictionType.GroundWaterProtectionZones,
        toNumber(parcel.subtractableAreas.groundWaterProtectionZonesChecked),
        "protectionLevel",
      ),

      // Add population protection zone restrictions
      ...createRestriction(
        RestrictionType.PopulationProtectionZones,
        toNumber(parcel.subtractableAreas.populationProtectionZonesChecked),
      ),

      // Add landscape restrictions
      ...createRestriction(
        RestrictionType.Landscape,
        toNumber(parcel.subtractableAreas.landscapeChecked),
      ),
    ],
  });

  return {
    parcelRestrictions: parcels.map(mapParcelToRestrictions),
  };
};

export const mapRequestBodyParcelsSubtraction = (
  parcels: AnyTodo,
  expenses?: AnyTodo,
  targetCrop?: AnyTodo,
) =>
  // actions that use reduxForm
  ({
    restrictions: parcels.map((parcel: AnyTodo) => ({
      parcelId: parcel.id,
      restrictions: [
        ...parcel.subtractableAreas.map(
          ({ isMaterial, isUsed, type, value }: AnyTodo) => ({
            type,
            value,
            isMaterial,
            isUsed,
          }),
        ),
      ],
    })),
    materials: expenses ? mapMaterials(expenses, targetCrop) : [],
  });

const mapMaterials = (expenses: AnyTodo, targetCrop: AnyTodo) =>
  expenses.map((expens: AnyTodo) => ({
    plantProtectionId: expens.material.id,
    targetOrganismIds: expens.pests.map((pest: AnyTodo) => pest.id),
    targetCropId: targetCrop.id,
    driftClass: expens.driftClass,
  }));

export const mapRequestBodyParcelSubtractionsGeometry = (
  parcelId: AnyTodo,
  subtractableAreas: AnyTodo,
) => ({
  parcelId,
  restrictions: [
    ...subtractableAreas
      .filter((sa: AnyTodo) => sa.isUsed === true)
      .map(({ type, value }: AnyTodo) => ({ type, value })),
  ],
});
