import React from "react";

import { Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";
import classNames from "classnames";
import queryString from "query-string";
import { FormattedMessage } from "react-intl";
import { generatePath, Link, useLocation } from "react-router-dom";

import { PRECISION_URLS } from "../../../../core/precision/precision.constants";

import analyticsIcon from "../../../../assets/img/icons/satellite/precision/dashboard/analytics.svg";
import analyticsDisabledIcon from "../../../../assets/img/icons/satellite/precision/dashboard/analytics_disabled.svg";
import planetIcon from "../../../../assets/img/icons/satellite/precision/dashboard/planet.svg";
import planetDisabledIcon from "../../../../assets/img/icons/satellite/precision/dashboard/planet_disabled.svg";
import requestQuoteDisabledIcon from "../../../../assets/img/icons/satellite/precision/dashboard/request-quote_disabled.svg";
import requestQuoteIcon from "../../../../assets/img/icons/satellite/precision/dashboard/request_quote.svg";
import testTubeIcon from "../../../../assets/img/icons/satellite/precision/dashboard/test-tube.svg";
import testTubeDisabledIcon from "../../../../assets/img/icons/satellite/precision/dashboard/test-tube_disabled.svg";
import { PRECISION_TABS } from "../../../../core/precision/containers/PrecisionDetail";
import {
  DashboardParcelTo,
  PrecisionFarmingProduct,
  SeasonTo,
} from "../../../../generated/api/satellite";
import { HistoryPrevState } from "../../../types";

type Props = {
  products: PrecisionFarmingProduct[];
  parcel: DashboardParcelTo;
  farmId: string;
  season?: SeasonTo;
};

const ActiveProductsCell = ({ farmId, parcel, products, season }: Props) => {
  const classes = useStyles();
  const location = useLocation();
  const disabledColor = "#F6F6F6";

  const planetIsActive = products.includes(
    PrecisionFarmingProduct.SENTINEL_HUB_MANAGEMENT_ZONES_SAVI,
  );
  const soilIsActive = products.includes(
    PrecisionFarmingProduct.INTERNAL_SOIL_SAMPLES,
  );
  const monitoringIsActive =
    products.includes(PrecisionFarmingProduct.PLANET_BIOMONITORING_SAVI) ||
    products.includes(PrecisionFarmingProduct.GISAT_BIOPHYSICAL_MONITORING);

  const yieldIsActive = products.includes(
    PrecisionFarmingProduct.INTERNAL_YIELD_MAPS,
  );

  const getRootUrl = (path: string) =>
    generatePath(
      `/farm/${farmId}/${PRECISION_URLS.servicesDetailV2()}/${path}`,
      {
        parcelId: parcel.parcelId,
        seasonId: season?.id ?? "",
      },
    );

  return (
    <div className={classes.icons}>
      <Tooltip
        title={<FormattedMessage id="PrecisionTableService.managementZones" />}
      >
        {planetIsActive ? (
          <Link
            className={classes.icon}
            onClick={(e) => e.stopPropagation()}
            style={{
              background: "#E0F5F0",
            }}
            to={{
              pathname: getRootUrl(PRECISION_TABS.zoneManagement.path),
              state: {
                previousLocation: { ...location },
              } as HistoryPrevState,
            }}
          >
            <img alt="planet" src={planetIcon} />
          </Link>
        ) : (
          <div
            className={classNames(classes.icon, classes.disabled)}
            onClick={(e) => e.stopPropagation()}
            style={{
              background: disabledColor,
            }}
          >
            <img alt="planet_disabled" src={planetDisabledIcon} />
          </div>
        )}
      </Tooltip>
      <Tooltip
        title={<FormattedMessage id="PrecisionTableService.soilSamples" />}
      >
        {soilIsActive ? (
          <Link
            className={classes.icon}
            onClick={(e) => e.stopPropagation()}
            style={{
              background: "#F8E6DB",
            }}
            to={{
              pathname: getRootUrl(PRECISION_TABS.soilSamples.path),
              state: {
                previousLocation: { ...location },
              } as HistoryPrevState,
            }}
          >
            <img alt="test-tube" src={testTubeIcon} />
          </Link>
        ) : (
          <div
            className={classNames(classes.icon, classes.disabled)}
            onClick={(e) => e.stopPropagation()}
            style={{
              background: disabledColor,
            }}
          >
            <img alt="test-tube_disabled" src={testTubeDisabledIcon} />
          </div>
        )}
      </Tooltip>
      <Tooltip
        title={<FormattedMessage id="PrecisionTableService.monitoring" />}
      >
        {monitoringIsActive ? (
          <Link
            className={classes.icon}
            onClick={(e) => e.stopPropagation()}
            style={{
              background: "#CCE7F5",
            }}
            to={{
              pathname: getRootUrl(PRECISION_TABS.monitoring.path),
              state: {
                previousLocation: { ...location },
              } as HistoryPrevState,
            }}
          >
            <img alt="analytics" src={analyticsIcon} />
          </Link>
        ) : (
          <div
            className={classNames(classes.icon, classes.disabled)}
            onClick={(e) => e.stopPropagation()}
            style={{
              background: disabledColor,
            }}
          >
            <img alt="analytics_disabled" src={analyticsDisabledIcon} />
          </div>
        )}
      </Tooltip>
      <Tooltip
        title={<FormattedMessage id="PrecisionTableService.yieldMaps" />}
      >
        {yieldIsActive ? (
          <Link
            className={classes.icon}
            onClick={(e) => e.stopPropagation()}
            style={{
              background: "#FFE1FC",
            }}
            to={{
              pathname: getRootUrl(PRECISION_TABS.yieldMaps.path),
              search: queryString.stringify({ seasonId: season?.id }),
              state: {
                previousLocation: { ...location },
              } as HistoryPrevState,
            }}
          >
            <img alt="request-quote" src={requestQuoteIcon} />
          </Link>
        ) : (
          <div
            className={classNames(classes.icon, classes.disabled)}
            onClick={(e) => e.stopPropagation()}
            style={{
              background: disabledColor,
            }}
          >
            <img alt="request-quote-disabled" src={requestQuoteDisabledIcon} />
          </div>
        )}
      </Tooltip>
    </div>
  );
};

export const useStyles = makeStyles(() => ({
  icons: {
    display: "flex",
    gap: 12,
  },
  icon: {
    padding: 4,
    height: 32,
    width: 32,
    borderRadius: "100%",
  },
  disabled: {
    cursor: "default",
  },
}));

export { ActiveProductsCell };
