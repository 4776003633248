import React from "react";

import { Paper } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";

import PrecisionMapModal from "../../../../../core/precision/components/PrecisionMapModal/PrecisionMapModal";
import PrecisionMapV2 from "../../../../../core/precision/components/PrecisionMapModal/PrecisionMapV2";
import SectionHeader from "../../../../../core/precision/components/ZoneManagement/SectionHeader";
import {
  YieldMapDetailResponse,
  SatelliteParcelV2,
} from "../../../../../generated/api/satellite";
import CfNoData from "../../../../../shared/components/charts/CfNoData/CfNoData";
import CfStaticMap from "../../../../../shared/components/specific/CfStaticMap/CfStaticMap";
import { useToggle } from "../../../../../shared/hooks/useToggle";

interface Props {
  data: YieldMapDetailResponse;
  parcelId: string;
  parcelData: SatelliteParcelV2;
}

const YieldMapZonesMap = (props: Props) => {
  const data = props.data;

  const { on: isOpen, setOff: closeDialog, setOn: openDialog } = useToggle();
  const classes = useStyles();

  const getLegend = (isModal?: boolean) => (
    <div>
      <SectionHeader
        headingTranslationId="YieldMaps.zoning.title"
        isModal={isModal}
      />
    </div>
  );

  return (
    <>
      <div className={classes.title}>
        <FormattedMessage id="YieldMaps.zoning.title" />
      </div>
      <Paper className={classes.paper}>
        <div
          className={classes.zonesMap}
          onClick={data ? openDialog : undefined}
        >
          {!data ? (
            <CfNoData />
          ) : (
            <CfStaticMap
              interactive={false}
              interpolate={false}
              mapId="raster-yield-map"
              classes={{
                map: classes.map,
              }}
              image={{
                url: data.rasterUrl,
                extent: [
                  data.rasterExtent.minX,
                  data.rasterExtent.minY,
                  data.rasterExtent.maxX,
                  data.rasterExtent.maxY,
                ],
              }}
            />
          )}
        </div>
        {isOpen && data && (
          <PrecisionMapModal
            header={null}
            legend={getLegend(true)}
            onClose={closeDialog}
            map={
              <PrecisionMapV2
                geometries={null}
                header={null}
                interpolate={false}
                parcel={props.parcelData}
                parcelId={props.parcelId}
                mapImage={{
                  url: data.rasterUrl,
                  extent: [
                    data.rasterExtent.minX,
                    data.rasterExtent.minY,
                    data.rasterExtent.maxX,
                    data.rasterExtent.maxY,
                  ],
                }}
              />
            }
          />
        )}
      </Paper>
    </>
  );
};

const useStyles = makeStyles(() => ({
  zonesMap: {
    width: "100%",
    height: "100%",
    minHeight: "368px",
    minWidth: "300px",
    cursor: "pointer",
    position: "relative",
  },
  paper: {
    height: "100%",
  },
  map: {
    padding: "4px",
    borderRadius: 0,
    "& .ol-viewport": {
      borderRadius: 0,
    },
  },
  title: {
    color: "#000",
    fontFamily: "Roboto",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineWeight: "16px",
    textTransform: "uppercase",
    padding: "0 0 6px",
  },
}));

export { YieldMapZonesMap };
