import { useEffect } from "react";

import { useQuery } from "@tanstack/react-query";
import { useField, useFormikContext } from "formik";
import { useIntl } from "react-intl";

import { RestrictionType } from "../../../../generated/api/agroevidence";
import { parcelsSubtractionsVegetationStripsQuery } from "../../../../shared/api/agroevidence/subtractableAreas/subtractableAreas.api";
import { useFarmIds } from "../../../../shared/api/client.utils";
import { AnyTodo } from "../../../../types";

import { ActionEphFormValues } from "../../ActionEph/actionEph.types";
import { InitialParcelToAdd } from "../actionOther.types";

const useVegetationStrips = () => {
  const { setFieldError, setFieldValue } =
    useFormikContext<ActionEphFormValues>();

  const [parcels] = useField("parcels");

  const farmIds = useFarmIds();
  const intl = useIntl();

  const filteredAndMapped = parcels.value
    .filter((parcel: InitialParcelToAdd) =>
      parcel.subtractableAreas?.water?.some(
        (area) =>
          area.type === RestrictionType.SlopeWater ||
          area.type === RestrictionType.SlopeVegetationStrip,
      ),
    )
    .flatMap(
      (parcel: InitialParcelToAdd) =>
        parcel.subtractableAreas?.water
          ?.filter(
            (area) =>
              area.type === RestrictionType.SlopeWater ||
              area.type === RestrictionType.SlopeVegetationStrip,
          )
          .map((area) => ({
            parcelId: parcel.id,
            restrictions: [
              {
                type: area.type,
                value: area.value,
                name: area.name,
              },
            ],
          })) || [],
    );

  const slopeWaterStripesCheck: AnyTodo = useQuery({
    ...parcelsSubtractionsVegetationStripsQuery({
      options: {
        enabled: true,
      },
      params: [
        {
          parcelRestrictions: filteredAndMapped,
        },
        { ...farmIds },
      ],
    }),
  });

  useEffect(() => {
    if (!slopeWaterStripesCheck.data) {
      return;
    }

    const merged = filteredAndMapped.map(
      (parcel: AnyTodo) => {
        const parcelIndex = parcels.value.findIndex(
          (rootParcel: AnyTodo) => rootParcel.id === parcel.parcelId,
        );
        const matchingSufficiency = slopeWaterStripesCheck.data.find(
          (sufficiency: AnyTodo) => sufficiency.parcelId === parcel.parcelId,
        );
        return {
          ...parcel,
          parcelIndex,
          isSufficient: matchingSufficiency
            ? matchingSufficiency.isSufficient
            : null,
        };
      },
      [slopeWaterStripesCheck.data],
    );

    merged.forEach((item: AnyTodo) => {
      if (!item.isSufficient) {
        setFieldValue(`parcels[${item.parcelIndex}]`, {
          ...parcels.value[item.parcelIndex],
          subtractableAreas: {
            ...parcels.value[item.parcelIndex].subtractableAreas,
            waterChecked: 0,
          },
        });

        setFieldError(
          `parcels.${item.parcelIndex}.subtractableAreas.waterChecked`,
          intl.formatMessage(
            {
              id: `parcels.protectionZones.${item.restrictions[0].type.toLocaleLowerCase()}`,
            },
            {
              name: item?.restrictions[0]?.name,
              value: item?.restrictions[0]?.value,
            },
          ),
        );
      }
    });
  }, [slopeWaterStripesCheck.data]);
};

export { useVegetationStrips };
